exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-connect-js": () => import("./../../../src/pages/connect.js" /* webpackChunkName: "component---src-pages-connect-js" */),
  "component---src-pages-endorsements-js": () => import("./../../../src/pages/endorsements.js" /* webpackChunkName: "component---src-pages-endorsements-js" */),
  "component---src-pages-espanol-biografia-js": () => import("./../../../src/pages/espanol-biografia.js" /* webpackChunkName: "component---src-pages-espanol-biografia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-district-js": () => import("./../../../src/pages/our-district.js" /* webpackChunkName: "component---src-pages-our-district-js" */)
}

